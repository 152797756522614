import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Erbfolge() {
  return (
    <LayoutXT>
      <h1 className="left">Erbfolge und Nachlassplanung durch Testament</h1>
      <div className="rem-of-13 gray-a lheight18">
        Im Rahmen unserer anspruchsvollen Rechtsberatung gestalten wir mit Ihnen
        Ihr Testament, abgestimmt auf Ihre persönliche Situation, wie
        beispielsweise eine Heirat, der Geburt eines Kindes, der Erwerb einer
        Immobilie oder auch der Betrieb eines Unternehmens. Insoweit empfehlen
        wir Ihnen altersunabhängig eine frühzeitige und gut durchdachte
        Nachlassplanung, da die gesetzliche Erbfolge oftmals die individuell
        gewollte Erbfolge nicht abdeckt.
        <br />
        <br />
        Wir beraten Sie als Privatperson, als Ehegatten oder auch als
        Unternehmer zu allen Fragen der erbrechtlichen Vermögensgestaltung -
        steueroptimiert und mit langfristiger Perspektive. So ist bei
        minderjährigen Erben oder bei mehreren Erben stets auch die Einsetzung
        eines Testamentsvollstreckers Ihres Vertrauens zu berücksichtigen.
        <br />
        <br />
        Wir begleiten Sie bei der Übergabe Ihres Vermögens an die nachfolgende
        Generation und entwickeln anhand Ihrer persönlichen Bedürfnisse und
        individuellen Vermögenswerte eine erbrechtliche Lösung nach Maß, damit
        Ihr Vermögen juristisch transparent und geordnet auf Ihre Erben
        übergeht, so dass Ihr Ehegatte und Abkömmlinge versorgt sind oder auch
        die Fortführung Ihres Unternehmens gesichert ist. Sollte im Einzelfall
        eine notarielle Beurkundung wie bei einem Erbvertrag sinnvoll oder
        notwendig sein, übernehmen wir die erbrechtliche Rechtsgestaltung in
        Zusammenarbeit mit einem ausgewählten Notariat aus der Region.
        <br />
        <br />
        Auf Wunsch wird selbstverständlich auch eine sichere Verwahrung Ihres
        Testaments in der amtlichen Verwahrung bei dem örtlichen Amtsgericht
        veranlasst.
      </div>
    </LayoutXT>
  )
}
